import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { GlassMagnifier } from 'react-image-magnifiers';

import ProductPriceSpider from 'gatsby-theme-husky/src/components/Products/ProductPriceSpider';
import ScreenRecognitionContext from 'gatsby-theme-husky/src/utils/context/screenRecognitionContext';
import { extractUrlFromMultiPicker } from 'gatsby-theme-husky/src/utils/umbracoControlsUtils';
import PlainImage from 'gatsby-theme-husky/src/common/Image/PlainImage';
import Image from 'gatsby-theme-husky/src/common/Image';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import BazaarVoice from 'gatsby-theme-husky/src/common/BazaarVoice';
import ProductDetailsButton from 'gatsby-theme-husky/src/common/ProductDetailsButton';

import { ProductDetailsProps } from 'gatsby-theme-husky/src/components/ProductDetails/models';
import { ESCAPE_KEY } from 'gatsby-theme-husky/src/components/ProductDetails/constants';

import 'gatsby-theme-husky/src/components/ProductDetails/ProductDetails.scss';
import './ProductDetailsOverride.scss';

const ProductDetails: FC<ProductDetailsProps> = ({
  productTitle,
  localProductImage,
  productImage,
  productEAN,
  productTitleWithSize,
  closeModalAriaLabel,
  productContent,
  dropdownButton,
  shops,
  productInfo,
  productFeature,
  ...itemProductDescriptions
}) => {
  const { variants } = (productContent && productContent.length && productContent[0]) || {};

  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const [isSupportingWebp, setIsSupportingWebp] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState<number | null>(0);

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOpenItem = (i) => {
    isOpen === i ? setIsOpen(null) : setIsOpen(i);
  };

  const handleEscFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === ESCAPE_KEY) {
      setIsModalVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscFunction);
    setIsSupportingWebp(
      document.createElement('canvas').toDataURL('image/webp')?.indexOf('data:image/webp') === 0
    );

    return () => {
      document.removeEventListener('keydown', handleEscFunction);
    };
  }, []);

  return (
    <div className="product-details-page" data-testid="products-details">
      <div className="product-details-page__image--wrapper">
        <div
          className="product-details-page__image"
          data-testid="product-details-image"
          role="button"
          tabIndex={0}
          onClick={handleModal}
        >
          {screenRecognitionContext.isMobile ? (
            <>
              {localProductImage ? (
                <Image localImage={localProductImage} alt={productTitle!} />
              ) : null}
              {!localProductImage && productImage ? (
                <PlainImage lazyLoad src={productImage} alt={productTitle!} />
              ) : null}
            </>
          ) : (
            <GlassMagnifier
              imageSrc={
                isSupportingWebp
                  ? localProductImage?.childImageSharp.fluid.srcWebp || productImage
                  : localProductImage?.childImageSharp.fluid.src || productImage
              }
              imageAlt={productTitle}
              square
              className="product-details-page__image--zoom"
              magnifierBorderColor="rgb(204, 204, 204)"
              magnifierBorderSize={1}
              magnifierSize="150px"
            />
          )}
        </div>
      </div>
      {isModalVisible ? (
        <div className="product-modal">
          <div
            className="product-modal__overlay"
            onClick={handleModal}
            role="button"
            aria-label={closeModalAriaLabel}
            tabIndex={0}
          />
          <div className="product-modal__body">
            <button
              aria-label={closeModalAriaLabel}
              className="product-modal__close"
              onClick={handleModal}
              type="button"
            />
            {localProductImage ? (
              <Image localImage={localProductImage} alt={productTitle!} />
            ) : null}
            {!localProductImage && productImage ? (
              <PlainImage lazyLoad src={productImage} alt={productTitle!} />
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="product-details-page__content--wrapper">
        <div className="product-details-page__content">
          <h2 className="product-details-page__title">{productTitle || productTitleWithSize}</h2>
          <BazaarVoice eanCode={productEAN} showOnlyRating />
          <div className="product-details-page__btn" data-testid="product-card-buttons">
            {dropdownButton ? (
              <ProductDetailsButton
                shops={shops}
                dropdownButton={dropdownButton}
                isModalVisible={isModalVisible}
              />
            ) : (
              <ProductPriceSpider productEAN={productEAN} />
            )}
          </div>
          {variants ? (
            <div className="product-details-page__variants">
              {variants.map(({ variantLink, variantText }) => (
                <Link
                  to={extractUrlFromMultiPicker(variantLink)}
                  key={variantText}
                  className="product-details-page__variants-size"
                  activeClassName="product-details-page__variants-size--active"
                >
                  {variantText}
                </Link>
              ))}
            </div>
          ) : null}

          {Object.values(itemProductDescriptions) && productFeature && productFeature?.length > 0 && (
            <div className="product-details-page__features">
              <div className="product-details-page__feature">
                {productFeature?.map((elem) =>
                  itemProductDescriptions[elem?.featureType[0]] ? (
                    <div className="product-details-page__feature-item" key={elem.title}>
                      <div className="product-details-page__feature-item-title">{elem.title}</div>
                      <ul className="product-details-page__features-items">
                        <li className="product-details-page__features-item">
                          <DangerouslySetInnerHtml
                            html={itemProductDescriptions[elem?.featureType[0]]}
                          />
                        </li>
                      </ul>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          )}

          {Object.values(itemProductDescriptions) && productInfo && productInfo?.length > 0 && (
            <div className="product-details-page__feature-accordion">
              {productInfo?.map((elem, i) =>
                itemProductDescriptions[elem?.infoType[0]] ? (
                  <div
                    key={i}
                    onClick={() => handleOpenItem(i)}
                    className={classNames({
                      open: isOpen === i,
                    })}
                  >
                    <div>{elem.title}</div>
                    <div>
                      <DangerouslySetInnerHtml
                        html={itemProductDescriptions[elem?.infoType[0]]}
                        className="product-details-page__feature-content"
                      />
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
